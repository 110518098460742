import { useRef, useEffect, lazy } from 'react';
import styles from './Accordion.module.scss';
import { ChevronDownV2 } from '~/components/icons';
import Typography from '~/components/shared/Typography';
import LegalAnnotation from '~/components/shared/LegalAnnotation';

const AccordionItem = ({ faq, active, onToggle, isFaq = false, index }) => {
  const {
    header,
    headerLegal,
    headerLegalToggle,
    headerTextColor,
    subhead1,
    subhead1Legal,
    subhead1LegalToggle,
    subhead2,
    subhead2Toggle,
    subhead2Legal,
    subhead2LegalToggle,
    subheadTextColor,
    iconColor,
    showJupiterLogo,
  } = faq;

  const faqRef = useRef();
  const eduRef = useRef();
  const ref = isFaq ? faqRef : eduRef;
  return (
    <li
      className={`${!isFaq ? styles.accordionItem : styles.faqAccordionItem}
      ${active ? styles.active : ''}
      ${showJupiterLogo && index == 0 && !isFaq && styles.jupiterSpacing}`}
    >
      <a
        className={styles.button}
        onClick={onToggle}
        style={{ color: '#005dac' || headerTextColor }}
      >
        <span>
          {header}
          {headerLegalToggle && <LegalAnnotation annotation={headerLegal} />}
        </span>
        <span className={`${styles.icon} ${active ? styles.active : ''}`}>
          <ChevronDownV2 style={{ fill: '#0096fa' || iconColor }} />
        </span>
      </a>
      <div
        className={styles.subheadWrapper}
        ref={ref}
        style={
          active ? { height: ref?.current?.scrollHeight } : { height: '0px' }
        }
      >
        <Typography
          variant="pbolder"
          className={!isFaq ? styles.subhead : styles.faqSubhead}
          style={{ color: subheadTextColor }}
        >
          <span dangerouslySetInnerHTML={{ __html: subhead1 }} />
          {subhead1LegalToggle && (
            <LegalAnnotation annotation={subhead1Legal} />
          )}
        </Typography>

        {subhead2Toggle && (
          <Typography
            variant="pbolder"
            className={`${styles.subheadSpacing} ${
              !isFaq ? styles.subhead : styles.faqSubhead
            }`}
            style={{ color: subheadTextColor }}
          >
            <span dangerouslySetInnerHTML={{ __html: subhead2 }} />
            {subhead2LegalToggle && (
              <LegalAnnotation annotation={subhead2Legal} />
            )}
          </Typography>
        )}
        {showJupiterLogo && index == 0 && !isFaq && (
          <div className={`${styles.jupiterLogo}`}>
            <img src="/images/satellite-powered.png" alt="satellite powered" />
          </div>
        )}
      </div>
    </li>
  );
};

export default AccordionItem;
