import React, { useRef } from 'react';
import styles from './Banner.module.scss';
import PhoneNumber from '~/components/shared/Fuse/PhoneNumber';
import Button from '~/components/shared/Button';
import LegalAnnotation from '~/components/shared/LegalAnnotation';
import useIntersectionObserver from '~/helpers/hooks/useIntersectionObserver';
import { fireElementViewedEvent } from '~/helpers/utils/fireTagular';
import { PhoneRebrand } from '~/components/icons';
import { useMonarchSlotContext } from '@redventures/cohesion-utils-react';

const BASE_DATA = {
  backgroundImage: 'acp-banner',
  borderColor: 'white',
  callCTA: true,
  callCTACopy: 'CALL',
  epqCopy: 'CHECK AVAILABILITY',
  epqShow: true,
  headerCopy:
    'You may be eligible to receive a $30 monthly credit through the FCC Affordable Connectivity Program',
  headerLegalToggle: false,
  headerFontColor: '#fff',
  iconColor: '#ffffff',
  showSubhead: false,
  subheadLegalToggle: false,
  subheadCopy1:
    'Hughes® is proud to participate in the Affordable Connectivity Program.',
  subheadCopy2:
    'Find out if you can get a discounted rate on your Internet plan.',
  subheadFontColor: '#fff',
};

export default function ACP_Banner() {
  const { data: monarchData } = useMonarchSlotContext();
  const acpBanner = useRef();

  const observeSections = useIntersectionObserver(
    [acpBanner],
    (el) =>
      fireElementViewedEvent(
        'ACP Banner',
        'ACP Banner Section',
        'ACP Banner',
        headerCopy
      ),
    undefined,
    // Fire tagular only when the full element is visible
    { threshold: 1.0 }
  );

  const {
    headerFontColor,
    headerCopy,
    headerLegalToggle,
    subheadCopy1,
    subheadLegalToggle,
    subheadCopy2,
    subheadFontColor,
    callCTA,
    epqCopy,
    epqShow,
    callCTACopy,
    backgroundImage,
    showSubhead,
  } = { ...BASE_DATA, ...monarchData };

  const bgImage = backgroundImage
    ? `/images/section/${backgroundImage}.jpg`
    : `/images/section/acp-banner.jpg`;
  return (
    <div
      className={styles.container}
      style={{ backgroundImage: `url(${bgImage})` }}
      ref={acpBanner}
    >
      <div className={styles.gridContainer}>
        <div>
          <div className="cell small-12">
            <div className={styles.content}>
              <div className={styles.heroContentWrapper}>
                <h1
                  className={styles.headline}
                  style={{
                    marginBottom: !showSubhead && `48px`,
                    color: headerFontColor,
                  }}
                >
                  <span dangerouslySetInnerHTML={{ __html: headerCopy }} />
                  {headerLegalToggle && (
                    <LegalAnnotation annotation="acp-legal" />
                  )}
                </h1>
                {showSubhead && (
                  <h2
                    className={styles.subHeadline}
                    style={{ color: subheadFontColor }}
                  >
                    {subheadCopy1}
                    {subheadLegalToggle && (
                      <LegalAnnotation annotation="acp-legal" />
                    )}{' '}
                    {subheadCopy2}
                  </h2>
                )}
              </div>
              <div className={styles.phoneButton}>
                {epqShow && (
                  <>
                    <Button
                      href="/order-online"
                      tracking={{
                        position: 'ACP BANNER EPQ',
                        location: 'ACP BANNER',
                        elementType: 'BUTTON',
                        text: 'Check Availablity',
                        actionOutcome: 'Redirect to Cart',
                      }}
                      fuse
                      className={`${styles.epqBtn} ${styles.btn}`}
                    >
                      {epqCopy}
                    </Button>
                  </>
                )}
                {callCTA && (
                  <>
                    <Button
                      tracking={{
                        position: 'ACP BANNER CTA',
                        location: 'ACP BANNER',
                        elementType: 'BUTTON',
                        text: 'Call now',
                        actionOutcome: 'Click to call',
                      }}
                      fuse
                      className={styles.btn}
                    >
                      <PhoneRebrand color={'#fff'} /> {callCTACopy}{' '}
                      <PhoneNumber />
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
