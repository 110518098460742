import React, { useRef } from 'react';
import styles from './ValueProps.module.scss';
import Typography from '~/components/shared/Typography';
import {
  ConferenceRebrand,
  ConnectRebrand,
  GamingRebrand,
  StreamRebrand,
  TrophyRebrand,
  PersonRebrand,
  UnlimitedRebrand,
  DollarRebrand,
  PlayRebrand,
  MapRebrand,
  SatelliteRebrand,
  HouseRebrand,
  ContractRebrand,
  OperatorRebrand,
  ThumbsUp,
  Wifi,
  Unlimited,
  Support,
  WrenchBrand,
} from '~/components/icons';
import useIntersectionObserver from '~/helpers/hooks/useIntersectionObserver';
import { fireElementViewedEvent } from '~/helpers/utils/fireTagular';
import LegalAnnotation from '~/components/shared/LegalAnnotation';
import Button from '~/components/shared/Button';
import { useMonarchSlotContext } from '@redventures/cohesion-utils-react';

const BASE_DATA = {
  backgroundColor: 'white',
  headline: 'Now you can do it all, wherever you live',
  headlineFontColor: '#0096fa',
  subhead: '',
  showCTA: false,
  ctaCopy: 'Upgrade Now',
  props: [
    {
      icon: 'person',
      header: 'Enjoy Internet anywhere, nationwide',
      headerLegalToggle: false,
      headerLegal: '',
      subhead1:
        'The new Hughesnet is faster than ever, with download speeds up to 100 Mbps',
      subhead1LegalToggle: true,
      subhead1Legal: 'generic-speed',
      subhead2Toggle: true,
      subhead2: 'and includes a Wi-Fi Modem featuring Wi-Fi 6 technology.',
      subhead2LegalToggle: false,
      subhead2Legal: '',
      disclaimerToggle: false,
      disclaimer: '*100 Mbps speeds available on select plans in select areas.',
    },
    {
      icon: 'satellite',
      header: 'Pick from service plans made for all users',
      headerLegalToggle: false,
      headerLegal: '',
      subhead1:
        'The new Hughesnet is faster than ever, with download speeds up to 100 Mbps',
      subhead1LegalToggle: true,
      subhead1Legal: 'generic-speed',
      subhead2Toggle: true,
      subhead2: 'and includes a Wi-Fi Modem featuring Wi-Fi 6 technology.',
      subhead2LegalToggle: false,
      subhead2Legal: '',
      disclaimerToggle: false,
      disclaimer: '*100 Mbps speeds available on select plans in select areas.',
    },
    {
      icon: 'thumbsup',
      header: 'Pick from service plans made for all users',
      headerLegalToggle: false,
      headerLegal: '',
      subhead1:
        'The new Hughesnet is faster than ever, with download speeds up to 100 Mbps',
      subhead1LegalToggle: true,
      subhead1Legal: 'generic-speed',
      subhead2Toggle: true,
      subhead2: 'and includes a Wi-Fi Modem featuring Wi-Fi 6 technology.',
      subhead2LegalToggle: false,
      subhead2Legal: '',
      disclaimerToggle: false,
      disclaimer: '*100 Mbps speeds available on select plans in select areas.',
    },
  ],
};

export default function ValueProps(metadata) {
  const valuePropsSection = useRef();

  const observeSections = useIntersectionObserver(
    [valuePropsSection],
    (el) =>
      fireElementViewedEvent(
        'value props',
        'value props section',
        'value props',
        headline
      ),
    undefined,
    // Fire tagular only when the full element is visible
    { threshold: 1.0 }
  );
  const { data: monarchData } = useMonarchSlotContext();
  const data = { ...BASE_DATA, ...monarchData };
  const {
    backgroundColor,
    headline,
    subhead,
    showCTA,
    ctaCopy,
    props,
    headlineFontColor,
  } = data;

  const getIcon = (icon) => {
    switch (icon) {
      case 'conference':
        return <ConferenceRebrand />;
      case 'dish':
        return <ConnectRebrand />;
      case 'gaming':
        return <GamingRebrand />;
      case 'play':
        return <StreamRebrand />;
      case 'trophy':
        return <TrophyRebrand />;
      case 'unlimited':
        return <UnlimitedRebrand />;
      case 'dollar':
        return <DollarRebrand />;
      case 'person':
        return <PersonRebrand />;
      case 'stream':
        return <PlayRebrand />;
      case 'map':
        return <MapRebrand />;
      case 'satellite':
        return <SatelliteRebrand />;
      case 'house':
        return <HouseRebrand />;
      case 'contract':
        return <ContractRebrand />;
      case 'operator':
        return <OperatorRebrand />;
      case 'thumbsup':
        return <ThumbsUp />;
      case 'wifi':
        return <Wifi />;
      case 'newUnlimited':
        return <Unlimited />;
      case 'support':
        return <Support />;
      case 'wrench':
        return <WrenchBrand />;
      default:
        return <ConferenceRebrand />;
    }
  };

  return (
    <section
      ref={valuePropsSection}
      id="upgrade"
      className={styles.container}
      style={{ backgroundColor: backgroundColor }}
    >
      <Typography variant="h2" className={styles.headline}>
        <span style={{ color: headlineFontColor || '#0096fa' }}>
          {headline}
        </span>
      </Typography>
      <Typography variant="h2" className={styles.subhead}>
        <span>{subhead}</span>
      </Typography>
      <div
        className={`${styles.threeItemContentWrapper} ${
          props.length == 4 && styles.fourItemContentWrapper
        }`}
      >
        {props.map((prop, index) => (
          <div key={index}>
            {getIcon(prop?.icon)}
            <div className={styles.content}>
              <Typography variant="h3" className={styles.heading}>
                <span dangerouslySetInnerHTML={{ __html: prop?.header }} />
                {prop?.headerLegalToggle && (
                  <LegalAnnotation annotation={prop?.headerLegal} />
                )}
              </Typography>
              <Typography variant="p" className={styles.subheading}>
                <span dangerouslySetInnerHTML={{ __html: prop?.subhead1 }} />
                {prop?.subhead1LegalToggle && (
                  <LegalAnnotation annotation={prop?.subhead1Legal} />
                )}{' '}
                {prop?.subhead2Toggle && (
                  <span dangerouslySetInnerHTML={{ __html: prop?.subhead2 }} />
                )}
                {prop?.subhead2LegalToggle && (
                  <LegalAnnotation annotation={prop?.subhead2Legal} />
                )}
              </Typography>
              {prop?.disclaimerToggle && (
                <Typography variant="disclaimer" className={styles.disclaimer}>
                  <span>{prop?.disclaimer}</span>
                </Typography>
              )}
            </div>
          </div>
        ))}
      </div>
      {showCTA && (
        <div className={styles.buttons}>
          <Button
            href="/order-online"
            tracking={{
              position: 'ACP BANNER EPQ',
              location: 'ACP BANNER',
              elementType: 'BUTTON',
              text: 'Check Availablity',
              actionOutcome: 'Redirect to Cart',
            }}
            fuse
            className={`${styles.btn}`}
          >
            {ctaCopy}
          </Button>
        </div>
      )}
    </section>
  );
}
