import React from 'react';
import Head from 'next/head';
import { HeroSkeleton } from '~/components/shared/Skeleton';
import MirHero from '~/components/base/v1/MIR Hero';
import ACP_Banner from '~/components/base/v1/ACP Banner';
import WholeWifiModule from '~/components/base/v1/Whole Home WiFi';
import EducationModule from '~/components/base/v1/Education Module';
import Banner from '~/components/base/v1/US News';
import ValueProps from '~/components/base/v1/Value Props';
import FinalEpq from '~/components/base/v1/Final EPQ';
import { getFilteredComponentName } from '~/helpers/utils/getFilteredComponentName';
import { MonarchSlot } from '@redventures/cohesion-utils-react';
import { useEffect, useContext } from 'react';
import { CohesionContext } from '@redventures/cohesion-utils-react';
import { logError } from '@red-digital/bricks';
import { noticeError } from '@helpers/utils/newRelic';
import { ERRORS } from '@constants/errors';
import LogRocket from 'logrocket';

const Home = () => {
  const cohesionData = useContext(CohesionContext);
  useEffect(() => {
    // Set monarch response in LogRocket
    window?.cohesion('monarch:done', () => {
      try {
        LogRocket.track('tracking', {
          audienceName: cohesionData.result.source.trafficFlow.value.name || 0,
        });
      } catch (error) {
        logError(error);
        noticeError(error, {
          type: ERRORS.LOGROCKET.type,
          message: ERRORS.LOGROCKET.message,
        });
      }
    });
  }, []);
  return (
    <>
      <Head>
        <title>Hughesnet® Internet | 1-855-386-1643 | Satellite Internet</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Hughesnet® is America's #1 choice for satellite internet. Save big with Award-Winning Hughesnet packages. Plus Free standard installation at no extra cost!"
        />
      </Head>

      {/* Hero Placement */}
      <MonarchSlot slotId="homeHero" skeleton={HeroSkeleton}>
        <MirHero />
      </MonarchSlot>

      {/* Monarch Slot Section 1 */}
      <MonarchSlot slotId="homeSection1">
        <ValueProps />
      </MonarchSlot>

      {/* Monarch Slot Section 2 */}
      <MonarchSlot slotId="homeSection2">
        <ACP_Banner />
      </MonarchSlot>

      {/* Monarch Slot Section 3 */}
      <MonarchSlot slotId="homeSection3">
        <WholeWifiModule />
      </MonarchSlot>

      {/* Monarch Slot Section 4 */}
      <MonarchSlot slotId="homeSection4">
        <EducationModule />
      </MonarchSlot>

      {/* Monarch Slot Section 5 */}
      <MonarchSlot slotId="homeSection5">
        <Banner />
      </MonarchSlot>

      {/* Monarch Slot Section 6 */}
      <MonarchSlot slotId="homeSection6">
        <FinalEpq />
      </MonarchSlot>

      {/* Monarch Slot Section 7 */}
      <MonarchSlot slotId="homeSection7" />

      {/* Monarch Slot Section 8 */}
      <MonarchSlot slotId="homeSection8" />

      {/* Monarch Slot Section 9 */}
      <MonarchSlot slotId="homeSection9" />

      {/* Monarch Slot Modal Section */}
      <MonarchSlot slotId="homeModal" />
    </>
  );
};

export default Home;
