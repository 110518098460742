import React, { useRef } from 'react';
import styles from './Banner.module.scss';
import useIntersectionObserver from '~/helpers/hooks/useIntersectionObserver';
import { fireElementViewedEvent } from '~/helpers/utils/fireTagular';
import { USNews24, Ribbon } from '~/components/icons';
import { useMonarchSlotContext } from '@redventures/cohesion-utils-react';

export default function Banner() {
  const BASE_DATA = {
    headerCopy:
      'Best Satellite Internet Service Provider of 2024 by U.S. News & World Report',
    icon: 'us-news',
  };
  const { data: monarchData } = useMonarchSlotContext();
  const data = { ...BASE_DATA, ...monarchData };
  const { headerCopy, icon } = data;

  const bannerRef = useRef();

  useIntersectionObserver(
    [bannerRef],
    (el) =>
      fireElementViewedEvent(
        'US News Banner 2024',
        'US News Banner Section',
        'US News Banner 2024',

        headerCopy
      ),
    undefined,
    // Fire tagular only when the full element is visible
    { threshold: 1.0 }
  );

  const getIcon = (icon) => {
    switch (icon) {
      case 'us-news':
        return <USNews24 />;
      case 'ribbon':
        return <Ribbon />;
      default:
        return <USNews24 />;
    }
  };

  return (
    <div className={styles.container} ref={bannerRef}>
      <section className={styles.section}>
        <div className={styles.content}>
          <span
            className={styles.headline}
            dangerouslySetInnerHTML={{ __html: headerCopy }}
          />
          {getIcon(icon)}
        </div>
      </section>
    </div>
  );
}
