import React, { useRef } from 'react';
import styles from './EducationModule.module.scss';
import Typography from '~/components/shared/Typography';
import useIntersectionObserver from '~/helpers/hooks/useIntersectionObserver';
import { fireElementViewedEvent } from '~/helpers/utils/fireTagular';
import Accordion from '~/components/shared/AccordionBrand';
import { useMedia } from '~/helpers/hooks';
import PhoneNumber from '~/components/shared/Fuse/PhoneNumber';
import Button from '~/components/shared/Button';
import { PhoneBlueV2 } from '~/components/icons/PhoneBlueV2';
import LegalAnnotation from '~/components/shared/LegalAnnotation';
import { useMonarchSlotContext } from '@redventures/cohesion-utils-react';

const BASE_DATA = {
  backgroundColor: '#FAFBFD',
  headline: 'Say hello to the NEW Hughesnet',
  pcaCta: 'CALL NOW',
  pcaHeader: 'Get all your questions answered today',
  pcaSubhead1:
    'Hughesnet Fusion plans might be available where you live. Fusion plans are great for those who want to enjoy a faster, more responsive Internet experience.',
  pcaSubhead2:
    'Chat through questions and plan details with an expert now. Learn about pricing, installation, data and more.',
  props: [
    {
      header: 'This is satellite internet reinvented',
      headerLegal: '',
      headerLegalToggle: false,
      subhead1:
        'The new Hughesnet plans are powered by JUPITER<sup>TM</sup> 3, the largest, most advanced commercial communications satellite.',
      subhead1Legal: '',
      subhead1LegalToggle: false,
      subhead2:
        'To get you online, we’ll install a satellite dish to send and receive information as well as a Wi-Fi modem, giving you secure and fast Internet access.',
      subhead2Toggle: true,
      subhead2Legal: '',
      subhead2LegalToggle: false,
      showJupiterLogo: true,
    },
    {
      header: 'With the new Hughesnet you get unlimited data',
      headerLegal: 'data-limit',
      headerLegalToggle: true,
      subhead1:
        'All Hughesnet plans come with unlimited data, so you’ll never get cut off or pay overage fees. Now you can do more of what you love online.',
      subhead1Legal: '',
      subhead1LegalToggle: false,
      subhead2: '',
      subhead2Toggle: false,
      subhead2Legal: '',
      subhead2LegalToggle: false,
      showJupiterLogo: true,
    },
    {
      header: 'Hassle-free installation by a certified professional',
      headerLegal: '',
      headerLegalToggle: false,
      subhead1:
        'A professional installer will bring your system right to your home. With Hughesnet, your Wi-Fi modem will be set up by your installer, who will connect up to two devices. Add optional Mesh Wi-Fi Nodes to eliminate dead zones in your home — also professionally installed when ordered with your Hughesnet system.',
      subhead1Legal: '',
      subhead1LegalToggle: false,
      subhead2: '',
      subhead2Toggle: false,
      subhead2Legal: '',
      subhead2LegalToggle: false,
      showJupiterLogo: true,
    },
  ],
  showPCA: false,
  sideImage: 'woman-on-laptop',
  subhead:
    'Available where you live! Even in places that are beyond the reach of cable and fiber. Now, with faster speeds, Whole Home Wi-Fi, the low-latency Hughesnet Fusion plan and unlimited data.',
  subheadLegal: 'data-limit',
  subheadLegalToggle: true,
};

export default function EducationModule() {
  const isMedia = useMedia();
  const { data: monarchData } = useMonarchSlotContext();
  const data = { ...BASE_DATA, ...monarchData };
  const {
    backgroundColor,
    headline,
    subhead,
    showPCA,
    pcaHeader,
    pcaSubhead1,
    pcaSubhead2,
    pcaCta,
    sideImage,
    subheadLegal,
    subheadLegalToggle,
    props,
  } = data;

  const educationModuleSection = useRef();
  useIntersectionObserver(
    [educationModuleSection],
    () =>
      fireElementViewedEvent(
        'education module',
        'education module section',
        'education module',
        headline
      ),
    undefined,
    { threshold: isMedia.desktop ? 1.0 : isMedia.tablet ? 0.75 : 0.5 }
  );

  return (
    <section
      ref={educationModuleSection}
      style={{ backgroundColor: backgroundColor }}
    >
      <div className={styles.container}>
        <div className={styles.contentWrapper}>
          <div>
            <Typography variant="h2" className={styles.headline}>
              <span>{headline}</span>
            </Typography>
          </div>
          <div>
            <Typography variant="pbolder" className={styles.subheading}>
              <span>
                {subhead}
                {subheadLegalToggle && (
                  <LegalAnnotation annotation={subheadLegal} />
                )}
              </span>
            </Typography>
          </div>
          <div className={styles.image}>
            <picture>
              <img
                src={`/images/section/${sideImage}.jpg`}
                alt="Woman on laptop"
                media="(min-width: 640px)"
                className={styles.image}
              />
            </picture>
          </div>
        </div>
        <div className={styles.accordionWrapper}>
          <Accordion
            tracking={{
              position: 'EDU MODULE',
              location: 'EDU MODULE',
              elementType: 'BUTTON',
              actionOutcome: 'TOGGLE OPEN',
            }}
            faqs={props}
            isFaq={false}
          />
          {showPCA ? (
            <div className={styles.pca}>
              <Typography variant="h2" className={styles.pcaHeader}>
                {pcaHeader}
              </Typography>
              <div className={styles.pcaSubContainer}>
                <Typography variant="pbolder" className={styles.pcaSubCopy}>
                  {pcaSubhead1}
                </Typography>
                <Typography variant="pbolder" className={styles.pcaSubCopy}>
                  {pcaSubhead2}
                </Typography>
                <Button
                  className={styles.pcaCta}
                  fuse
                  tracking={{
                    position: '',
                    location: 'MASTHEAD',
                    elementType: 'BUTTON',
                    actionOutcome: 'click to call',
                    htmlId: 'MASTHEAD_C2C',
                  }}
                >
                  <PhoneBlueV2 />
                  &nbsp;&nbsp;
                  <span className={styles.pcaCtaCopy}>
                    {pcaCta}&nbsp;
                    <PhoneNumber />
                  </span>
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
}
